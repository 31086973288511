import React, {useState, useEffect, useMemo} from "react";
import {Modal, message, List, Button, Checkbox} from "antd";
import {DeleteOutlined, ToolOutlined} from "@ant-design/icons";
import {InputNumberWithFormItem, SelectWithFormItem} from "../../Common/Input";
import {useSelector} from "react-redux";
import "./AddLabourLines.scss";
import {mapPositionToSelect, sortPositions} from "../../Requirements/Common/PositionSelector";
import {createLabourLines} from "../../../services/labour";
import {refreshTimeline} from "../../../services/timeline";
import {ApiTimelineItemType} from "../../../constants/constants";

const Title = ({selected}) => (
    <>
        <ToolOutlined/> Add Labour Lines to {selected.length} {selected.length > 1 ? "Events" : "Event"}
    </>
);

const getNewId = () => new Date().getTime();

const AddLabourLines = ({selected, disabled}) => {
    const [isVisible, setVisible] = useState(false);
    const [enableBookings, setEnableBookings] = useState(false);
    const [labourLines, setLabourLines] = useState([{index: 0, Quantity: 0}]);
    const [isLoading, setIsLoading] = useState(false);

    const usedPositions = useMemo(() => {
        return labourLines.map((ll) => ll.PositionId);
    }, [labourLines]);

    useEffect(() => {
        if (!isVisible) {
            setLabourLines([{index: getNewId(), Quantity: 0}]);
            setEnableBookings(false);
        }
    }, [isVisible]);

    async function handleOk() {
        setIsLoading(true);
        try {
            const payload = {
                TaskIds: selected.map((t) => t.Id),
                EnableBookings: enableBookings,
                NewLabourLines: labourLines
                    .filter((ll) => ll.Quantity > 0)
                    .map(({Quantity, PositionId}) => ({
                        Quantity,
                        PositionId,
                    })),
            };
            await createLabourLines(payload);
            await refreshTimeline(ApiTimelineItemType.Task);
            message.success(`Labour lines were added to ${selected.length} ${selected.length > 1 ? "events" : "event"}`);
            close();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function close() {
        setTimeout(() => {
            setVisible(false);
        }, 100);
    }

    const onSingleAdd = () => {
        setLabourLines([
            ...labourLines,
            {
                index: getNewId(),
                Quantity: 0,
            },
        ]);
    };

    const onLineUpdated = (item) => {
        setLabourLines(labourLines.map((ll) => (ll.index === item.index ? item : ll)));
    };

    const onLineDeleted = (index) => {
        setLabourLines(labourLines.filter((ll) => ll.index !== index));
    };

    return (
        <>
            <div
                onClick={() => {
                    if (!disabled) setVisible(true);
                }}>
                Add Labour Lines
            </div>

            <Modal
                className="add-labour-lines-modal"
                title={<Title selected={selected}/>}
                visible={isVisible}
                onOk={handleOk}
                onCancel={close}
                maskClosable={false}
                confirmLoading={isLoading}
                okText={`Add Labour Lines`}>
                <div>
                    <List
                        header={
                            <div className="labour-items-header">
                                <div className="position">Position</div>
                                <div className="qty">Qty</div>
                                <div className="actions">
                                    <Button type="primary" size="small" onClick={onSingleAdd}>
                                        Add
                                    </Button>
                                </div>
                            </div>
                        }
                        size="small"
                        dataSource={labourLines}
                        renderItem={(item) => (
                            <List.Item key={item.index}>
                                <EditLabourLineRow
                                    item={item}
                                    usedPositions={usedPositions}
                                    onLineUpdated={onLineUpdated}
                                    onLineDeleted={onLineDeleted}
                                />
                            </List.Item>
                        )}></List>
                    <div className="center">
                        <Checkbox checked={enableBookings} onChange={(e) => setEnableBookings(e.target.checked)}>
                            Enable Crew Bookings
                        </Checkbox>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const EditLabourLineRow = ({item = {}, usedPositions, onLineUpdated, onLineDeleted}) => {
    const [qty, setQty] = useState(item.Quantity);

    const positions = useSelector((state) => state.labour.positions);

    const deleteRow = () => {
        onLineDeleted(item.index);
    };

    return (
        <div className="labour-items-row">
            <div className="position">
                <SelectWithFormItem
                    allowClear
                    showSearch
                    style={{width: "100%"}}
                    className="ant-form-item-without-validation"
                    placeholder="Select position"
                    value={item.PositionId}
                    onChanged={(val) => {
                        onLineUpdated({
                            ...item,
                            PositionId: val,
                        });
                    }}
                    options={sortPositions(positions).map((p) => mapPositionToSelect(p, usedPositions))}></SelectWithFormItem>
            </div>
            <div className="qty">
                <InputNumberWithFormItem
                    value={qty}
                    style={{width: "100%"}}
                    onChange={setQty}
                    onChanged={() => {
                        onLineUpdated({
                            ...item,
                            Quantity: qty,
                        });
                    }}
                />
            </div>

            <div className="actions">
                <Button onClick={deleteRow} type="link" size="small">
                    <DeleteOutlined className="delete"/>
                </Button>
            </div>
        </div>
    );
};

export default AddLabourLines;
