import React, {useState, useEffect, useRef} from "react";
import {Modal, Form, Space, InputNumber, Divider} from "antd";
import {ToolOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {InputWithFormItem} from "../../../Common/Input";
import {createRuleset, loadRulesets, updateRuleset} from "../../../../services/rulesets";
import {formatToFixedFlex} from "../../../../helpers/text";
import './AddRulesetModal.scss';

const AddRulesetModal = ({visible, onClose, itemToEdit}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const form = useRef();

    useEffect(() => {
        if (!activePortfolioId || !form?.current) return;

        if (itemToEdit) form.current.setFieldsValue(itemToEdit); else form.current.resetFields();
    }, [activePortfolioId, itemToEdit, form]);

    const onOk = async () => {
        try {
            const values = await form.current.validateFields();

            const {OvertimeMultiplier2, OvertimeStartAfter2} = values;

            if (OvertimeMultiplier2 && !OvertimeStartAfter2) {
                throw new Error("Both Overtime 2 fields must be filled or left empty.");
            }

            const payload = {
                Id: itemToEdit?.Id,
                AccountId: activePortfolioId,
                Name: values.Name,
                OvertimeMultiplier1: values.OvertimeMultiplier1,
                OvertimeStartAfter1: values.OvertimeStartAfter1,
                OvertimeMultiplier2: values.OvertimeMultiplier2,
                OvertimeStartAfter2: values.OvertimeStartAfter2,
            };

            setConfirmLoading(true);

            const apiAction = payload?.Id > 0 ? updateRuleset : createRuleset;

            await apiAction(payload);
            await loadRulesets(activePortfolioId);

            setConfirmLoading(false);
            onCancel();

        } catch (error) {
            console.error("Validation failed:", error);
        }
    };

    const onCancel = () => {
        form.current.resetFields();
        onClose();
    };

    return (<Modal
        className="add-ruleset-modal"
        title={<><ToolOutlined/> &nbsp; Labour Cost Calculation Ruleset</>}
        visible={visible}
        maskClosable={false}
        confirmLoading={confirmLoading}
        onOk={onOk}
        onCancel={onCancel}
        onClose={onClose}
        okText="Save"
        cancelText="Cancel"
        width={"560px"}>
        <Form
            ref={form}
            name="add-ruleset"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            layout="horizontal"
            size="middle"
            requiredMark={false}>
            <InputWithFormItem
                name={["Name"]}
                label={"Ruleset Name"}
                hasFeedback
                rules={[{required: true, message: "Ruleset"}]}
                className="form-item ruleset-name-input"
            />
            <Divider orientation="left" className="divider-first">
                Overtime 1
            </Divider>
            <Form.Item label="Overtime 1 multiplier" className="form-item">
                <Space>
                    <Form.Item name={["OvertimeMultiplier1"]} noStyle
                               rules={[{required: true, message: "Overtime 1 multiplier"}]}>
                        <InputNumber
                            className="input-number"
                            parser={(v) => parseFloat(v) && parseFloat(v).toFixed(2)}
                            formatter={formatToFixedFlex}
                            min={0}
                            controls={false}
                        />
                    </Form.Item>
                    <span> times regular rate.</span>
                </Space>
            </Form.Item>
            <Form.Item label="Overtime 1 starts after:" className="form-item">
                <Space>
                    <Form.Item name={["OvertimeStartAfter1"]} noStyle
                               rules={[{required: true, message: "Overtime 1 starts after"}]}>
                        <InputNumber
                            className="input-number"
                            min={1}
                            step={1}
                            controls={false}
                            onChange={(value) => {
                                if (!Number.isInteger(value)) {
                                    form.current.setFieldsValue({OvertimeStartAfter1: Math.floor(value)});
                                }
                            }}
                        />
                    </Form.Item>
                    <span> hours in a labour line.</span>
                </Space>
            </Form.Item>
            <Divider orientation="left" className="divider-first">
                Overtime 2
            </Divider>
            <Form.Item label="Overtime 2 multiplier" className="form-item">
                <Space>
                    <Form.Item
                        name={["OvertimeMultiplier2"]}
                        noStyle
                        dependencies={["OvertimeStartAfter2"]}
                        rules={[({getFieldValue}) => ({
                            validator(_, value) {
                                const startAfter2 = getFieldValue("OvertimeStartAfter2");
                                if (!value && !startAfter2) {
                                    return Promise.resolve();
                                }
                                if (value && startAfter2) {
                                    return Promise.resolve();
                                }
                                if (!value) {
                                    return Promise.reject(new Error("Overtime 2 multiplier"));
                                }
                                return Promise.resolve();
                            },
                        }),]}
                    >
                        <InputNumber
                            className="input-number"
                            parser={(v) => parseFloat(v) && parseFloat(v).toFixed(2)}
                            formatter={formatToFixedFlex}
                            min={0}
                            controls={false}
                        />
                    </Form.Item>
                    <span> times regular rate.</span>
                </Space>
            </Form.Item>
            <Form.Item label="Overtime 2 starts after:" className="form-item">
                <Space>
                    <Form.Item
                        name={["OvertimeStartAfter2"]}
                        noStyle
                        dependencies={["OvertimeMultiplier2", "OvertimeStartAfter1"]}
                        rules={[({getFieldValue}) => ({
                            validator(_, value) {
                                const multiplier2 = getFieldValue("OvertimeMultiplier2");
                                const startAfter1 = getFieldValue("OvertimeStartAfter1");
                                if (!value && !multiplier2) {
                                    return Promise.resolve();
                                }
                                if (value && multiplier2) {
                                    if (value <= startAfter1) {
                                        return Promise.reject(new Error("Overtime 2 start hour must be greater than Overtime 1 start hour."));
                                    }
                                    return Promise.resolve();
                                }
                                if (!value) {
                                    return Promise.reject(new Error("Overtime 2 starts after"));
                                }
                                return Promise.resolve();
                            },
                        }),]}
                    >
                        <InputNumber
                            className="input-number"
                            min={1}
                            step={1}
                            controls={false}
                            onChange={(value) => {
                                if (!Number.isInteger(value)) {
                                    form.current.setFieldsValue({OvertimeStartAfter2: Math.floor(value)});
                                }
                            }}
                        />
                    </Form.Item>
                    <span> hours in a labour line.</span>
                </Space>
            </Form.Item>
        </Form>
    </Modal>);
};

export default AddRulesetModal;