import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {startLoading, endLoading} from "../redux/reducers/detailsPanel";
import {getContact} from "../services/contacts";
import {message} from "antd";
import {pathCollection} from "../helpers/paths";
import useUrlWithPortfolioId from "./useUrlWithPortfolioId";
import {convertApiContactTypeToContactType, RequirementType} from "../constants";
import {getLabourLine} from "../services/labour";

export const useNavigation = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const goToContact = (contact) => {

        let contactType = convertApiContactTypeToContactType(contact.Type);

        history.push(urlWithPortfolioId(pathCollection.contactsPath[contactType]));

        setTimeout(() => {
            dispatch(startLoading({item: contact, type: contactType}));

            getContact(contact.Id)
                .then((item) => dispatch(endLoading({item, type: contactType})))
                .catch(() => message.error("Server error"));
        }, 200);
    };

    const goToLabourLine = (crewBooking) => {

        history.push(urlWithPortfolioId(pathCollection.requirementsPath[RequirementType.Labour]));

        let labourLine = crewBooking?.LabourLine;

        labourLine = {
            ...labourLine, Task: crewBooking.Task
        };

        setTimeout(() => {
            dispatch(startLoading({item: labourLine, type: RequirementType.Labour}));

            getLabourLine(labourLine.Id)
                .then((response) => dispatch(endLoading({item: response})))
                .catch(() => message.error("Server error"));
        }, 200);
    };

    return {goToContact, goToLabourLine};
};
