import {List, Button, Select, message} from "antd";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {QuestionOutlined, CheckOutlined, DeleteOutlined, EyeOutlined, SearchOutlined} from "@ant-design/icons";
import {
    ApiContactType, ApiTimelineItemStatus, ApiTimelineItemStatusName, ApiTimelineItemType, SidePanelItemType,
} from "../../../constants/constants";
import "./CrewManagement.scss";
import {formatContactName, formatTeamMemberName} from "../../../helpers/text";
import {useDispatch, useSelector} from "react-redux";
import {loadContacts} from "../../../services/contacts";
import {loadTeam} from "../../../services/team";
import {
    createOrUpdateBooking, deleteBooking, getCreateOrUpdateBookingPayload, getEvent
} from "../../../services/timeline";
import {refreshPositions} from "../../../services/positions";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import {pathCollection} from "../../../helpers/paths";
import {useHistory} from "react-router-dom";
import {endLoading, startLoading, detailsSubPanel} from "../../../redux/reducers/detailsPanel";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {selectFilterOption, SelectWithFormItem} from "../../Common/Input";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {mapPositionToSelect, sortPositions} from "../../Requirements/Common/PositionSelector";

const {openEdit: openEditSubPanel} = detailsSubPanel;

const TimelineItemStatusIcon = {
    [ApiTimelineItemStatus.None]: <>&nbsp;&nbsp;&nbsp;&nbsp;</>, // <MinusOutlined />,
    [ApiTimelineItemStatus.Tentative]: <QuestionOutlined/>, [ApiTimelineItemStatus.Confirmed]: <CheckOutlined/>,
};

const getRowId = (record) => (record.Id ? record.Id.toString() : "new");

const statusOptions = [ApiTimelineItemStatus.None, ApiTimelineItemStatus.Confirmed, ApiTimelineItemStatus.Tentative].map((status) => ({
    value: status, icon: TimelineItemStatusIcon[status], label: (<>
        {TimelineItemStatusIcon[status]} {ApiTimelineItemStatusName[status]}
    </>),
}));

export const sortBookings = (bookings) => {
    return _.sortBy(bookings, [(b) => b.TeamMember?.Contact?.LastName, (b) => formatContactName(b.TeamMember?.Contact || {}), (b) => b.Id,]);
};

const TeamSelector = ({teamMembers, contacts, defaultTeam, ...props}) => (<Select {...props}>
    <Select.OptGroup label="Project Team Members">
        {teamMembers.map((item) => (<Select.Option key={item.Id} value={item.Id} label={formatTeamMemberName(item)}>
            {formatTeamMemberName(item)}
        </Select.Option>))}
    </Select.OptGroup>
    <Select.OptGroup label="All Contacts">
        {contacts.map((item) => (
            <Select.Option key={item.Id} value={`contact_${item.Id}`} label={formatContactName(item, false)}
                           contactid={item.Id}>
                {formatContactName(item, false)}
            </Select.Option>))}
    </Select.OptGroup>
</Select>);

const EditCrewRow = ({item = {}, onChange, onDelete, teamMembers, contacts, projectId}) => {
    const [teamMemberValue, setTeamMemberValue] = useState(item.TeamMemberId);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const positions = useSelector((state) => state.labour.positions);

    useEffect(() => setTeamMemberValue(item.TeamMemberId), [item.TeamMemberId]);

    useEffect(() => {
        if (positions.length === 0) refreshPositions();
    }, []);

    const save = ({
                      status = item.Status,
                      positionId = item.LabourLine?.Position?.Id,
                      contact,
                      teamMember = item.TeamMemberId,
                  }) => {

        let position = positions.find((p) => p.Id === positionId);
        let rate, unit;

        if (status !== item.Status || teamMember !== item.TeamMemberId) {
            rate = item?.Rate;
            unit = item?.Unit;
        } else {
            rate = position?.Cost;
            unit = position?.Unit;
        }

        let payload = getCreateOrUpdateBookingPayload({
            booking: item,
            id: item.Id,
            projectId,
            taskId: item.TaskId,
            bookingStatus: status,
            rate,
            unit,
            positionId,
            teamMember,
            contact,
            notes: item.Notes,
            syncTimeFrame: item.SyncTimeFrame,
            labourLineId: item.LabourLine?.Id,
        });

        return createOrUpdateBooking(payload)
            .then((response) => onChange(response.Task))
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            });
    };

    const deleteRow = async () => {
        const {Id: itemId} = item;
        try {
            if (itemId) {
                await deleteBooking([itemId]);
            }
        } finally {
            onDelete(itemId);
        }
    };

    const onSearchContactsClick = () => {
        dispatch(openEditSubPanel({
            item: {...item, ProjectId: projectId, PositionName: item.LabourLine?.Position?.Name},
            type: SidePanelItemType.ContactsSearch,
        }));
    };

    const goToDetails = () => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Booking]));
        setTimeout(() => {
            dispatch(startLoading({item, type: SidePanelItemType.Booking}));
            getEvent(item.Id, projectId, ApiTimelineItemType.Booking)
                .then((item) => dispatch(endLoading({item})))
                .catch(() => message.error("Server error"));
        }, 100);
    };

    return (<div className="details-crew-item">
        <div className="status">
            <Select
                value={item.Status}
                dropdownClassName="status-ddl"
                optionLabelProp="icon"
                dropdownStyle={{width: "200px"}}
                onChange={(value) => {
                    // When status changes, use item?.Rate and item?.Unit
                    save({status: value});
                }}
                options={statusOptions}
            />
        </div>
        <div className="role">
            <SelectWithFormItem
                allowClear
                value={item.LabourLine?.Position?.Id}
                className="ant-form-item-without-validation"
                showSearch={true}
                style={{width: "100%", maxWidth: "300px"}}
                placeholder="Select a Position"
                options={sortPositions(positions).map((p) => mapPositionToSelect(p))}
                onChanged={(value) => {
                    save({positionId: value || null});
                }}
            />
        </div>
        <div className="name">
            <TeamSelector
                allowClear={true}
                value={teamMemberValue}
                teamMembers={teamMembers}
                contacts={contacts}
                showSearch
                dropdownMatchSelectWidth={false}
                placement="bottomRight"
                filterOption={selectFilterOption}
                onChange={(value, option) => {
                    const contactId = option?.contactid;
                    if (contactId) {
                        save({teamMember: null, contact: {Id: contactId}}).then(() => loadTeam(projectId));
                    } else {
                        save({teamMember: value || null});
                    }
                    setTeamMemberValue(value);
                }}
                dropdownRender={(menu) => (<>
                    {menu}
                    <div className="btn-quick-create">
                        <Button type="dashed" onClick={() => setIsQuickCreateContactVisible(true)}>
                            + Add a Contact
                        </Button>
                        <Button type="dashed" onClick={() => onSearchContactsClick()}>
                            <SearchOutlined/> Search
                        </Button>
                    </div>
                </>)}></TeamSelector>
        </div>

        <div className="actions">
            <Button onClick={goToDetails} type="link" size="small">
                <EyeOutlined className="details"/>
            </Button>
            <Button onClick={deleteRow} type="link" size="small">
                <DeleteOutlined className="delete"/>
            </Button>
        </div>

        <CreateContact
            apiContactType={ApiContactType.Person}
            isVisible={isQuickCreateContactVisible}
            onClose={() => setIsQuickCreateContactVisible(false)}
            onContactCreated={(contactId) => {
                save({teamMember: null, contact: {Id: contactId}}).then(() => loadTeam(projectId));
            }}
        />
    </div>);
};

const CrewManagement = ({bookings, projectId, taskId, onChange, onDelete}) => {
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const allTeamMembers = useSelector((state) => state.team.project[projectId]) || [];
    const allContacts = useSelector((state) => state.contacts.contacts);
    const people = allTeamMembers.filter((t) => t.Contact === null || t.Contact?.Type === ApiContactType.Person);

    const allTeamMemberContactIds = allTeamMembers.map((tm) => tm.ContactId);

    const peopleContacts = allContacts.filter((c) => c.Type === ApiContactType.Person && !allTeamMemberContactIds.includes(c.Id));

    const projectRoles = useSelector((state) => state.tags.projectRoles[projectId] || []);
    const roles = useSelector((state) => state.tags.roles).filter((t) => projectRoles.indexOf(t) === -1);

    useEffect(() => {
        if (!projectId) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolioId);
        }

        if (allTeamMembers.length === 0) {
            loadTeam(projectId);
        }
    }, [projectId, taskId]);
    return (<div>
        <List
            header={null}
            size="small"
            dataSource={sortBookings(bookings)}
            renderItem={(item, index) => (<List.Item key={getRowId(item)}>
                <EditCrewRow
                    item={item}
                    projectId={projectId}
                    teamMembers={people}
                    contacts={peopleContacts}
                    projectRoles={projectRoles}
                    allRoles={roles}
                    onDelete={onDelete}
                    onChange={onChange}
                    activePortfolioId={activePortfolioId}
                />
            </List.Item>)}></List>
    </div>);
};
export default CrewManagement;
