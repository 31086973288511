import {Button, Col, Row, Select} from "antd";
import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {InputNumberWithFormItem, SelectWithFormItem} from "../../../Common/Input";
import {moneyFormatter, moneyParser} from "../../../../helpers/money";
import {LabourCostUnit} from "../../../../constants/constants";
import {RedoOutlined} from "@ant-design/icons";
import PositionSelector from "../../Common/PositionSelector";

const toLabourCostUnit = (positionType) => {
    if (positionType === "Flat") return LabourCostUnit.Flat;

    return LabourCostUnit.Hour;
};

const PositionBlock = ({form, itemToEdit, save, isOptionalFieldDisabled}) => {
    const positions = useSelector((state) => state.labour.positions);
    const sourceDisabled = isOptionalFieldDisabled || !itemToEdit?.Position;

    const onResetCost = () => {
        const position = positions.find((p) => p.Id === itemToEdit.Position?.Id);
        const Cost = position ? position.Cost : 0;
        const Unit = position ? toLabourCostUnit(position.Unit) : LabourCostUnit.Hour;

        form.setFieldsValue({
            Cost,
            Unit,
        });
        save();
    };

    const isCostDefault =
        itemToEdit?.Position?.Cost === itemToEdit?.Cost && toLabourCostUnit(itemToEdit?.Position?.Unit) === itemToEdit?.Unit;

    return (
        <>
            <PositionSelector
                form={form}
                itemToEdit={itemToEdit}
                save={save}
                name={["Position", "Id"]}
                isOptionalFieldDisabled={isOptionalFieldDisabled}></PositionSelector>
            <Row>
                <Col span={12}>
                    <InputNumberWithFormItem
                        disabled={sourceDisabled}
                        name="Cost"
                        label="Rate"
                        formatter={moneyFormatter}
                        parser={moneyParser}
                        onChanged={save}
                        min={0}
                        formItemProps={{
                            labelCol: {span: 16},
                            wrapperCol: {span: 4},
                            className: "ant-form-item-without-validation",
                        }}
                    />
                </Col>
                <Col span={6}>
                    <SelectWithFormItem
                        disabled={sourceDisabled}
                        label={"    "}
                        name={["Unit"]}
                        style={{width: "90px"}}
                        onChanged={save}
                        formItemProps={{
                            colon: false,
                            labelAlign: "left",
                            labelCol: {span: 4},
                            wrapperCol: {span: 2},
                            className: "ant-form-item-without-validation",
                        }}>
                        <Select.Option key="Item" value={LabourCostUnit.Hour}>
                            /Hour
                        </Select.Option>
                        <Select.Option key="Day" value={LabourCostUnit.Flat}>
                            /Flat
                        </Select.Option>
                    </SelectWithFormItem>
                </Col>
                <Col span={2}>
                    <Button
                        hidden={isCostDefault || isOptionalFieldDisabled}
                        onClick={onResetCost}
                        className="reset-cost"
                        type="link"
                        title="Reset to Labour Line Rate"
                        size="small">
                        <RedoOutlined/>
                    </Button>
                </Col>
            </Row>
            <InputNumberWithFormItem disabled={isOptionalFieldDisabled} name="Quantity" label="Quantity" min={0}
                                     onChanged={save}/>
        </>
    );
};

export default PositionBlock;
