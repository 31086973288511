import React, {useEffect, useState} from "react";
import {Card, Row, Col, Button} from "antd";
import WorkWeekSettingsModal from "./WorkWeekSettingsModal";
import {loadWorkWeekSettings} from "../../../../services/workWeekSettings";
import {dayOptions} from "../../../../constants";

const WorkWeekSettings = ({activePortfolio}) => {
    const [isAddModalVisible, setAddModalVisible] = useState(false);
    const [isReplaceModalVisible, setReplaceModalVisible] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [isSaved, setIsSaved] = useState(false);

    const onEdit = (item) => {
        setItemToEdit(item);
        setAddModalVisible(true);
    };

    const onCloseModal = () => {
        setAddModalVisible(false);
        setReplaceModalVisible(false);
    };

    useEffect(() => {
        if (!activePortfolio) {
            return;
        }

        loadWorkWeekSettings(activePortfolio.Id)
            .then((settings) => {
                setItemToEdit(settings);
            })
            .catch((error) => {
                console.error("Failed to load work week settings:", error);
            });

    }, [activePortfolio, isSaved]);

    return (<>
        <Card
            type="inner"
            title={<Row justify="space-between" align="middle">
                <Col>Work Week Settings</Col>
                <Col>
                    <Button type="primary" size="small" onClick={() => onEdit(itemToEdit)}>Edit</Button>
                </Col>
            </Row>}
        >
            <Row className="row-margin">
                <Col span={24}>
                    Start work week
                    on {itemToEdit?.StartDayOfWeek !== undefined ? dayOptions.find(day => day.value === itemToEdit.StartDayOfWeek)?.label : "N/A"}.
                </Col>
            </Row>

            {itemToEdit?.StandardHoursThreshold && itemToEdit?.OvertimeRate ? (
                <>
                    <Row className="row-margin">
                        <Col span={24}>Overtime starts after {itemToEdit?.StandardHoursThreshold || "N/A"} regular hours
                            in a
                            work week.</Col>
                    </Row>
                    <Row>
                        <Col span={24}>Weekly OT Multiplier: {itemToEdit?.OvertimeRate || "N/A"} times regular
                            rate.</Col>
                    </Row>
                </>
            ) : (

                <Row>
                    <Col span={24}>No Weekly Overtime.</Col>
                </Row>
            )}
        </Card>
        <WorkWeekSettingsModal
            itemToEdit={itemToEdit}
            visible={isAddModalVisible}
            activePortfolioId={activePortfolio?.Id}
            onClose={onCloseModal}
            onSave={() => setIsSaved(!isSaved)} // Pass the onSave prop to the modal
        />
    </>);
};

export default WorkWeekSettings;