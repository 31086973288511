import {Form, Row, Col, Button, Select} from "antd";
import React from "react";
import "./FinancialsBlock.scss";
import {formatAmount, moneyFormatter, moneyParser} from "../../../helpers/money";
import {InputNumberWithFormItem, SelectWithFormItem} from "../../Common/Input";
import {formatDurationFromHours} from "../../../helpers/time";
import {RedoOutlined} from "@ant-design/icons";
import {LabourCostUnit} from "../../../constants/constants";
import {useSelector} from "react-redux";
import {useNavigation} from "../../../hooks/useNavigation";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import moment from "moment";

const toLabourCostUnit = (positionType) => {
    if (positionType === "Flat") return LabourCostUnit.Flat;

    return LabourCostUnit.Hour;
};


const FinancialsBlock = ({form, itemToEdit, save, isOptionalFieldDisabled}) => {
    const {goToLabourLine} = useNavigation();
    const dateTimeFormats = useDateTimeFormat();
    const positions = useSelector((state) => state.labour.positions);

    const Position = positions.find((p) => p.Id === itemToEdit?.LabourLine?.PositionId);
    itemToEdit = {
        ...itemToEdit, Position,
    };

    const sourceDisabled = isOptionalFieldDisabled || !itemToEdit?.Position;

    const {
        RegularHours = 0,
        RegularHoursOverForty = 0,
        RegularCost = 0,
        OverFortyHoursCost = 0,
        Overtime1Hours = 0,
        Overtime2Hours = 0,
        Overtime1Cost = 0,
        Overtime2Cost = 0,
        TotalHours = 0,
        TotalCost = 0
    } = itemToEdit?.Financials || {};

    const onResetRate = () => {
        const position = positions.find((p) => p.Id === itemToEdit.Position?.Id);
        const Rate = position ? position.Cost : 0;
        const Unit = position ? toLabourCostUnit(position.Unit) : LabourCostUnit.Hour;

        form.setFieldsValue({
            Unit, Rate,
        });
        save();
    };

    const isCostDefault = itemToEdit?.Position?.Cost === itemToEdit?.Rate && toLabourCostUnit(itemToEdit?.Position?.Unit) === itemToEdit?.Unit;

    return (<div className="financials-block">
        <Row>
            <Col span={24} style={{border: '0px solid red', paddingLeft: '10px'}}>
                <Form.Item
                    label={<span className="grey">Work Week</span>}
                    className="ant-form-item-without-validation"
                    labelCol={{span: 6}}>
                    {itemToEdit?.WorkWeek?.StartDate ? `${moment(itemToEdit.WorkWeek.StartDate).format(dateTimeFormats.date)} - ${moment(itemToEdit.WorkWeek.EndDate).format(dateTimeFormats.date)}` : "N/A"}                    </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{border: '0px solid red', paddingLeft: '10px'}}>
                <Form.Item
                    label={<span className="grey">Weekly Hours</span>}
                    className="ant-form-item-without-validation"
                    labelCol={{span: 6}}>
                    {formatDurationFromHours(itemToEdit?.WeeklyFinancials?.WeeklyTotalHours)}</Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{border: '0px solid red', paddingLeft: '10px'}}>
                <Form.Item
                    label={<span className="grey">RHRT</span>}
                    className="ant-form-item-without-validation"
                    labelCol={{span: 6}}>
                    {formatDurationFromHours(itemToEdit?.WeeklyFinancials?.RegularHoursRunningTotal)}</Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{border: '0px solid red', paddingLeft: '10px'}}>
                <Form.Item label={<span className="grey">Rate</span>}
                           className="ant-form-item-without-validation"
                           labelCol={{span: 6}}>
                    <Row>
                        <Col style={{border: '0px solid black'}}>
                            <InputNumberWithFormItem
                                name="Rate"
                                disabled={sourceDisabled}
                                formatter={moneyFormatter}
                                parser={moneyParser}
                                onChanged={save}
                                min={0}
                                formItemProps={{className: "ant-form-item-without-validation"}}
                            />
                        </Col>
                        <Col style={{border: '0px solid black'}}>
                            <SelectWithFormItem
                                label={""}
                                name={["Unit"]}
                                style={{width: "90px"}}
                                disabled={sourceDisabled}
                                onChanged={save}
                                formItemProps={{
                                    colon: false, labelAlign: "left", className: "ant-form-item-without-validation"
                                }}
                            >
                                <Select.Option key="Item" value={LabourCostUnit.Hour}>/Hour</Select.Option>
                                <Select.Option key="Day" value={LabourCostUnit.Flat}>/Flat</Select.Option>
                            </SelectWithFormItem>
                        </Col>
                        <Col style={{border: '0px solid black'}}>
                            <Button
                                hidden={isCostDefault || isOptionalFieldDisabled}
                                disabled={sourceDisabled}
                                onClick={onResetRate}
                                className="reset-cost"
                                type="link"
                                title="Reset to Labour Line Rate"
                                size="small">
                                <RedoOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Col>
        </Row>
        {itemToEdit?.LabourLine?.PositionId && (<>
            <Row>
                <Col span={24} style={{border: '0px solid red', paddingLeft: '10px'}}>
                    <Form.Item
                        label={<span className="grey">+/- Hours</span>}
                        className="ant-form-item-without-validation"
                        labelCol={{span: 6}}>
                        {formatDurationFromHours(itemToEdit?.LabourLine?.MealBreak)}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11} style={{border: '0px solid black'}}>
                    <Form.Item
                        labelCol={{span: 14}}
                        label={<span className="grey">Regular Hours</span>}
                        className="ant-form-item-without-validation">
                        {formatDurationFromHours(RegularHours)}
                    </Form.Item>
                    <Form.Item
                        labelCol={{span: 14}}
                        label={<span className="grey">Regular Hours <br/><span>Over 40</span></span>}
                        className="ant-form-item-without-validation">
                        {formatDurationFromHours(itemToEdit?.WeeklyFinancials?.WeeklyOvertimeHours)}
                    </Form.Item>
                    <Form.Item label={<span className="grey">OT 1 Hours</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 14}}>
                        {formatDurationFromHours(Overtime1Hours)}
                    </Form.Item>
                    <Form.Item label={<span className="grey">OT 2 Hours</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 14}}>
                        {formatDurationFromHours(Overtime2Hours)}
                    </Form.Item>
                    <Form.Item label={<span className="grey">Total Hours</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 14}}>
                        {formatDurationFromHours(TotalHours)}
                    </Form.Item>
                </Col>
                <Col span={13} style={{border: '0px solid black'}}>
                    <Form.Item label={<span className="grey">Regular Cost</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 11}}>
                        {formatAmount(RegularCost || 0)}
                    </Form.Item>
                    <Form.Item
                        label={<span className="grey">Over 40 Hours <br/><span>Cost</span></span>}
                        className="ant-form-item-without-validation"
                        labelCol={{span: 11}}>
                        {formatAmount(OverFortyHoursCost || 0)}
                    </Form.Item>
                    <Form.Item label={<span className="grey">OT 1 Cost</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 11}}>
                        {formatAmount(Overtime1Cost || 0)}
                    </Form.Item>
                    <Form.Item label={<span className="grey">OT 2 Cost</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 11}}>
                        {formatAmount(Overtime2Cost || 0)}
                    </Form.Item>
                    <Form.Item label={<span className="grey">Total Cost</span>}
                               className="ant-form-item-without-validation"
                               labelCol={{span: 11}}>
                        {formatAmount(TotalCost || 0)}
                    </Form.Item>
                </Col>
            </Row>
        </>)}
        <Row>
            <Col span={24}
                 style={{border: '0px solid red', paddingLeft: '10px', display: 'flex', justifyContent: 'center'}}>
                <Button type="primary" onClick={() => goToLabourLine(itemToEdit)}>
                    Go To Labour Line
                </Button>
            </Col>
        </Row>
    </div>);
};

export default FinancialsBlock;