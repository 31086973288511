import {CheckOutlined, EyeOutlined, QuestionOutlined} from "@ant-design/icons";
import {Button, Col, List, message, Row} from "antd";
import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    ApiTimelineItemStatus, ApiTimelineItemType, LabourCostUnit, SidePanelItemType
} from "../../../../constants/constants";
import {formatAmount, formatRate} from "../../../../helpers/money";
import {pathCollection} from "../../../../helpers/paths";
import {formatTeamMemberName} from "../../../../helpers/text";
import {eventDateRange} from "../../../../helpers/time";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {openEdit} from "../../../../redux/reducers/detailsPanel";
import {getEvent} from "../../../../services/timeline";
import {sortBookings} from "../../../Timeline/CrewManagement";

const TimelineItemStatusIcon = {
    [ApiTimelineItemStatus.None]: "",
    [ApiTimelineItemStatus.Tentative]: <QuestionOutlined/>,
    [ApiTimelineItemStatus.Confirmed]: <CheckOutlined/>,
};

const CrewBookingsBlock = ({itemToEdit}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const formats = useDateTimeFormat();

    const goToBooking = (booking) => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Booking]));
        getEvent(booking.Id, itemToEdit.Task.Project.Id, ApiTimelineItemType.Booking)
            .then((item) => dispatch(openEdit({item, type: SidePanelItemType.Booking})))
            .catch(() => message.error("Server error"));
    };

    return (<List
        dataSource={sortBookings(itemToEdit.WorkCallBookings)}
        size="small"
        className="table-in-panel"
        header={<Row>
            <Col span={18}>Name</Col>
            <Col span={6} className="cost-header">
                Cost
            </Col>
        </Row>}
        renderItem={(booking) => (<List.Item>
            <Row>
                <Col span={1} className="booking-status-icon">
                    {TimelineItemStatusIcon[booking.Status]}
                </Col>
                <Col span={18}>
                    {booking.TeamMember && formatTeamMemberName(booking.TeamMember)}
                    {!booking.TeamMember && "(unassigned)"}
                    {!booking.SyncTimeFrame && booking.StartDateTime && booking.EndDateTime && ` (${eventDateRange(booking.StartDateTime, booking.EndDateTime, formats, booking.IsTimeOnly, booking.IsAllDay)})`}
                    {(itemToEdit.Cost !== booking.Rate || itemToEdit.Unit !== booking.Unit) && (` ${formatRate(booking.Rate, booking.Unit)}`)}
                </Col>
                <Col span={3}>{formatAmount(booking.TotalCost || 0)}</Col>
                <Col span={1} offset={1} className="">
                    <div className="goto-event goto-event-labour ">
                        <Button type="link" size="small" onClick={() => goToBooking(booking)}>
                            <EyeOutlined/>
                        </Button>
                    </div>
                </Col>
            </Row>
        </List.Item>)}
    />);
};

export default CrewBookingsBlock;
